<template>
  <div>
    <v-row v-for="(el, idx) in myMeta" :key="idx" class="text-left ma-0">
        <v-col :cols="isRoot ? 6 : 12" sm="12">
          <h3
              v-if="el.type === 'L'"
              @click="changeShow(idx)"
              class="mouse-pointer"
          >{{el.name}}</h3>
          <h4 v-else
              @click="changeShow(idx)"
              class="mouse-pointer"
          >{{el.name}}</h4>
          <v-expand-transition>
            <div v-show="el.show">
              <v-data-table
                  v-if="attributeValuesToView(el.attributes)"
                  :headers="attributesTableHeaders"
                  :items="el.attributeValues"
                  disable-pagination
                  hide-default-header
                  hide-default-footer
                  class="elevation-1 attributes-values-table"
                  dense>
              </v-data-table>
              <attributes-view :meta="el.children" :vals="vals" :is-root="false"/>
            </div>
          </v-expand-transition>
        </v-col>

    </v-row>
  </div>
</template>

<script>
import moment from "moment-timezone";


function appendValues(l, vals) {
  if (!l || l.length === 0) {
    return []
  }
  console.log('1l', l)
  l.forEach(function(item, index, object) {
    if (!vals[item.code] || item.isSystem || !item.isVisible) {
      object.splice(index, 1);
    } else {
      item.value = vals[item.code]
    }
    item.children = appendValues(item.children, vals)
  });
  console.log(l)
  return l;
}

export default {
  name: "AttributesView",
  components: {},
  props: ['meta', 'vals', 'isRoot'],
  data() {
    return {
      attributesTableHeaders: [
        { value: 'name'},
        { value: 'value' },
      ],
      notShow: [],
    }
  },
  computed: {
    myMeta() {
      if (!this.meta) {
        return []
      }
      let meta = this.meta
          .map( m => {
          m.show = true
          m.attributeValues = this.attributeValuesToView(m.attributes)
          return m
      })
          .filter(e => e.children || e.attributeValues.length > 0)
      this.notShow.forEach(e => {
        meta[e].show = false
      })
      return meta
    },
    list() {
      console.log('3m', this.meta)
      return appendValues(this.meta, this.vals);
    }
  },
  methods: {
    changeShow(idx) {
      let pos = this.notShow.indexOf(idx)
      if (pos !== -1) {
        this.notShow.splice(pos, 1)
      } else {
        this.notShow.push(idx)
      }
    },
    attributeValuesToView(meta) {
      if (!meta || !this.vals) {
        return []
      }
      return meta.slice()
          .filter(m => {
            return !m.isSystem && m.isVisible && this.vals[m.code]
          })
          .sort((a, b) => a.order - b.order)
          .map(m => {
            let value = this.vals[m.code].value
            if (m.type === 'DTME') {
              value = moment(value).format('DD-MM-YYYY HH:mm:ss')
            } else if (m.type === 'D') {
              value = moment(value).format('DD-MM-YYYY')
            } else if (m.type === 'B') {
              value = value ? 'Истина' : 'Ложь'
            }
            return {
              name: m.name,
              value: value
            }
          })
    },
  },
}
</script>

<style scoped>
.mouse-pointer {
  cursor: pointer !important;
}

.attributes-values-table {
  max-width: 600px;
}
</style>