<template>
    <document-view :header="currentDocument.name"
                   :isArchive="attributeValue('c_15309')"
                   :loading="currentDocumentLoading">
      <v-row class="ma-0" v-if="currentDocument.available">
        <v-col cols="12" class="d-flex justify-start">
          <v-progress-circular
              v-if="stateChangeLoading"
              indeterminate
          ></v-progress-circular>
          <v-btn
              class="mx-3"
              v-for="s in currentDocument.available"
              :key="s.name"
              :color="errorState === s.name ? 'deep-orange accent-1' : ''"
              @click.stop="moveState(s.name)"
              :disabled="stateChangeLoading"
          >
            {{s.caption}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col>
          <div style="text-align: left">
            <span v-if="user && user.isAdmin"><strong>ID:</strong> {{currentDocument.id}}<br/></span>
            <strong>Инициатор:</strong> {{mainAttributes.creator}}<br/>
            <strong>Рег №:</strong> {{mainAttributes.regNum}}<br/>
            <strong>Дата регистрации:</strong> {{mainAttributes.regDate}}<br/>
            <strong>Дата последнего перехода:</strong> {{mainAttributes.lastStateChange}}<br/>
            <strong>Статус:</strong> {{mainAttributes.state}}<br/>
            <span v-if="mainAttributes && mainAttributes.remark">
                <strong>Комментарий инициатора:</strong> {{mainAttributes.remark}}<br/>
                </span>
          </div>
        </v-col>
      </v-row>
      <attributes-view
          :meta="currentDocument.attributesMeta"
          :vals="currentDocument.attributes"
          :is-root="true"
      />
    </document-view>
</template>

<script>
import DocumentView from "../DocumentView/DocumentView";
import {documentMoveState} from "../../api/document";
import moment from "moment-timezone";
import {mapActions, mapState} from "vuex";
import attributesView from "../../components/AttributesView";

function getAttributeValue(doc, code) {
  if (doc && doc.attributes && doc.attributes[code]) {
    return doc.attributes[code]
  }
  return {
    value: null,
  }
}

export default {
  name: "Main",
  data: function () {
    return {
      errorState: '',
      stateChangeLoading: false,
      attributesTableHeaders: [
        { value: 'name'},
        { value: 'value' },
      ],
    }
  },
  computed: {
    ...mapState('document', ['currentDocument', 'currentDocumentLoading']),
    user() {
      return this.$store.getters.getUser;
    },
    listDocumentsLink() {
      return '/documents/'+this.$route.params.typeID+'/list'
    },
    mainAttributes() {
      return {
        creator: this.attributeValue('c_10013'),
        regNum: this.attributeValue('c_10012'),
        regDate: moment(this.attributeValue('c_10003')).format('DD-MM-YYYY HH:mm:ss'),
        lastStateChange: this.attributeValue('c_15308') ? moment(this.attributeValue('c_15308')).format('DD-MM-YYYY HH:mm:ss') : 'Нет',
        state: this.attributeValue('c_10005'),
        remark: this.attributeValue('c_10006'),
      }
    },
    attributeValuesToView() {
      if (!this.currentDocument.attributesMeta) {
        return []
      }
      return this.currentDocument.attributesMeta.slice()
          .filter(m => {return this.currentDocument.attributes[m.code] && !m.isSystem && m.isVisible})
          .sort((a, b) => a.order - b.order)
          .map(m => {
            let value = this.currentDocument.attributes[m.code].value
            if (m.type === 'DTME') {
              value = moment(value).format('DD-MM-YYYY HH:mm:ss')
            } else if (m.type === 'D') {
              value = moment(value).format('DD-MM-YYYY')
            } else if (m.type === 'B') {
              value = value ? 'Истина' : 'Ложь'
            }
        return {
          name: m.name,
          value: value
        }
      })
    },
  },
  methods: {
    ...mapActions('document', ['fetchCurrentDocument']),
    moveState(state) {
      this.stateChangeLoading = true
      documentMoveState(this.$route.params.docID, this.$store.getters.getUser.personalEqmID, state, this.$store.getters.getAuthToken)
        .then((r) => {
          if (r.success) {
            this.errorState = ''
          } else {
            this.errorState = state
            console.log('ошибка смены статуса', r)
          }
        })
      .finally(() => {
        this.fetchCurrentDocument(this.$route.params.docID)
        this.stateChangeLoading = false
      })
    },
    attributeValue(code) {
      return getAttributeValue(this.currentDocument, code).value
    }
  },
  mounted() {
  },
  components: {
    'DocumentView': DocumentView,
    'attributesView': attributesView,
  }
}
</script>

<style scoped>
</style>